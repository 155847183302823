<template>
  <div class="storeTop">
    <storePrefList :list="storeCountList" :path="path"/>
  </div>
</template>

<script>
import storePrefList from "@/components/parts/storePrefList.vue";
import {Mixin} from '@/mixins/common'
import axios from 'axios';
import { mapActions } from "vuex"

export default {
  data() {
    return {
      storeCountList: {},
      path: '',
      title: '',
      description: ''
    };
  },
  components: {
    storePrefList
  },
  mixins:[Mixin],
  created (){
    this.pageInit(this.$route)
  },
  mounted () {
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      'setshareData'
    ]),

    refresh() {
      this.storeCountList = {}
      this.title = ''
      this.description = ''
    },
    pageInit(route) {
      this.refresh()
      this.getStoreCountList(route)
    },
    async getStoreCountList(route){
      const url = this.api + '/brands/' + route.params.code + '/stores/'
      // console.log(url);
      await axios.get(url)
      .then((res) => {
        this.storeCountList = res.data.lists

        // meta
        const title = res.data.brand.brand_name_full + 'のお店'
        this.title = title
        this.description = title + 'トップページ。' + res.data.brand.brand_name_full + 'のお店をエリア、都道府県から探すことができます。'
        this.$emit('updateHead')

        // sharedata
        const shareData = {}
        shareData.title = this.title
        shareData.text = res.data.brand.brand_name_full + 'の店舗トップページ。'
        shareData.url = window.location.href
        this.setshareData(shareData)


      })
      .catch((e) =>{
        // console.log(e);
        // console.log('item imgs not found')
      })
    }
  }
}
</script>

<style>
.storeTop{
  margin: 1px;
}
</style>
