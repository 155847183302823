<template functional>
  <ul class="pref__list">
    <li v-for="(area, index) in props.list" :key="index">
      <label :for="'pref' + area.id">{{area.area_name_ja}}</label>
      <input type="checkbox" :id="'pref' + area.id">
      <span class="arrow"><font-awesome-icon icon="chevron-right" /></span>
      <ul :id="'prefareaU' + area.id" class="pref__list--inner">
        <li v-for="pref in area.lists" :key="pref.pcode">
          <router-link :to="props.path + pref.pcode + '/'" v-scroll-to="{el: '#brandNav' ,container: '.container',duration: 300,easing: 'linear',offset: -46,force: true,x: false,y: true}">
            <span class="name">{{pref.name_ja}}</span>
            <span class="num">{{pref.count}}</span>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>

export default {
  props: ['list', 'path']
}
</script>
